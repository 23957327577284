<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-user')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <!-- 由于这里的编辑、删除按钮的控制条件和公共组件的不一样，为了公共组件的简洁性这里按钮重写 -->
        <el-button
          class="opt-btn"
          v-if="isUpdate && !isSelf(scope.row.userName)"
          icon="el-icon-edit"
          size="mini"
          type="primary"
          plain
          @click="handleEditData(scope.row.id)"
          >{{ $t('general.edit') }}</el-button
        >
        <el-button
          v-if="
            isSwitch &&
            isNormalStatus(scope.row.status) &&
            !isSelf(scope.row.userName)
          "
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          type="warning"
          @click="handleEnableClick(scope.row, 'disable')"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="
            isSwitch &&
            !isNormalStatus(scope.row.status) &&
            !isSelf(scope.row.userName)
          "
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row, 'enable')"
          >{{ $t('general.enable') }}</el-button
        >
        <el-button
          class="opt-btn"
          v-if="isDelete && !isSelf(scope.row.userName)"
          icon="el-icon-delete"
          size="mini"
          type="danger"
          plain
          @click="handleDeleteClick(scope.row)"
          >{{ $t('general.delete') }}</el-button
        >
        <el-button
          class="opt-btn"
          v-if="isLogs"
          icon="el-icon-tickets"
          size="mini"
          plain
          @click="handleLogsData(scope.row.userName)"
          >{{ $t('general.logs') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import i18n from '@/i18n'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/user.search'
import { contentTableConfig } from './config/user.table'
import { usePermission } from '@/hooks/use-permission'
import { cloneDeep } from 'lodash' // 导入loadsh插件
// import { formatDateTime } from '@/utils/util.js'
import { ElMessage, ElMessageBox } from 'element-plus'

import { useRouter } from 'vue-router'

// 表格基础配置项
const tableOptions = {
  moduleName: 'user', // 所属的模块
  pageName: 'users', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  isShowDeleteBtn: false,
  isShowEditBtn: false,
  pageAction: 'user/getUserListByPage', // 获取表格的Action
  pageCountGetter: 'user/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'user/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)
const isLogs = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'logs'
)
const isUpdate = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'edit'
)
const isDelete = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'delete'
)

const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)

const isNormalStatus = (status) => {
  return status === '00'
}

const store = useStore()
const { t } = i18n.global
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const pageContentRef = ref('')
const pageSearchRef = ref('')

const operator = ref('')
const initUserInfo = async () => {
  const res = await store.dispatch('user/getUserInfo')
  operator.value = res?.data?.data?.userName || ''
}

const isSelf = computed(() => {
  return (userName) => {
    return userName === operator.value
  }
})

const formateData = (data) => {
  const formateObject = cloneDeep(data)
  formateObject.tenantId = data.tenantId ? data.tenantId.join(',') : ''
  return formateObject
}

const handleQueryClick = (queryInfo) => {
  pageContentRef.value.getPageData(formateData(queryInfo))
}

const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/users/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/users/edit/${id}`)
}

const handleNewData = () => {
  router.push(`/users/add`)
}
const handleLogsData = (name) => {
  router.push(`/users/logs/${name}`)
}

const handleDeleteClick = (item) => {
  ElMessageBox.confirm(
    t('popup.global-delete-tip'),
    t('popup.confirm-warning-title'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      handleMessageCommit('user/handleDeleteUser', item.id).then(() => {
        pageContentRef.value.getPageData()
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: t('popup.cancel-delete-tip')
      })
    })
}

const handleEnableClick = async (row, type) => {
  const url = type === 'enable' ? 'handleEnableUser' : 'handleDisableUser'
  handleMessageCommit(`user/${url}`, row.id).then(() => {
    pageContentRef.value.getPageData()
  })
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formateData(formData))
}
initUserInfo()
</script>
