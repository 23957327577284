import {
  getCommonParamsOptions,
  getParamsOptionsAsync
} from '@/utils/common.js'

export const searchFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'userName',
      type: 'input',
      label: 'user.user-name'
    },
    {
      field: 'email',
      type: 'input',
      label: 'user.email'
    },
    {
      field: 'mobile',
      type: 'input',
      label: 'user.phone'
    },
    // {
    //   field: 'firstName',
    //   type: 'input',
    //   label: 'user.first-name'
    // },
    // {
    //   field: 'middleName',
    //   type: 'input',
    //   label: 'user.middle-name'
    // },
    // {
    //   field: 'lastName',
    //   type: 'input',
    //   label: 'user.last-name'
    // },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'user.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        props: {
          multiple: true
        }
      },
      options: [],
      handler: () =>
        getParamsOptionsAsync('tenantList', {
          isNeedPreFix: false
        })
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status')
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
